<template>
  <div class="password-change">
    <h1>Сменить пароль</h1>

    <ui-form :errors="errors" @submit.prevent="submit">
      <provider-error
        v-for="field in fields"
        :key="field.name"
        :error="errors[field.name]"
        #default="{isAlert}"
      >
        <ui-input
          v-model="values[field.name]"
          :name="field.name"
          :type="field.type"
          :required="field.required"
          :label="field.label"
          :isAlert="isAlert"
        />
      </provider-error>

      <ui-button>
        Сменить
      </ui-button>
    </ui-form>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import ProviderError from '../../components/provider/ProviderError';
import UiForm from '../../components/ui/UiForm';
import UiInput from '../../components/ui/UiInput';
import UiButton from '../../components/ui/UiButton';
import UiLoader from '../../components/ui/UiLoader';

import { authApi } from '../../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../../mixins/emitUpdateHeight.mixin';

export default {
  name: 'password-change',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    UiInput,
    UiForm,
    UiButton,
    UiLoader,
    ProviderError,
  },
  data() {
    return {
      fields: [
        { name: 'password', type: 'password', label: 'Пароль', required: true },
      ],
      values: {},
      errors: {},
      loading: false,
      success: false,
    };
  },

  mounted() {
    this.emitUpdateHeight();
  },

  methods: {
    async submit() {
      const token = this.$route.query.token;
      if (!token) return;

      this.loading = true;

      const { errors } = await authApi.changePassword(token, this.values);

      this.loading = false;

      if (errors) {
        this.errors = errors;
        this.emitUpdateHeight();
        return;
      }

      this.errors = {};
      this.values = {};

      this.$router.push('/authorization');
    },
  },
};
</script>

<style lang="scss">
.password-change {
  width: 500px;
  margin: 0 auto;
  position: relative;
}
</style>
