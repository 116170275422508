<template>
  <div class="request-row">
    <div class="request-row__header" @click="openModal">
      <div>
        <h2>{{ requestSummary.title }}</h2>
        <p>{{ requestSummary.description }}</p>
      </div>
      <ui-select
        v-model="selectedStatus"
        :options="requestStatuses"
        @input="changeRequestStatus"
      />
    </div>

    <ui-modal v-model="modalVisible">
      <div class="request-row__modal">
        <div
          v-for="field in outputFields"
          :key="field.key"
          class="request-row__field"
        >
          <span class="request-row__label">{{ field.label }}</span>

          <div class="request-row__value">
            <div v-if="field.type === 'files'">
              <a
                v-for="file in field.value"
                :key="file"
                :href="file.link"
                target="_blank"
              >
                {{ file.name }}
              </a>
            </div>
            <p v-else>
              {{ field.value }}
            </p>
          </div>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import { requestApi } from '../../api/request';
import { requestCategories } from '../../consts/request';
import UiModal from '../ui/UiModal.vue';
import UiSelect from '../ui/UiSelect';

export default {
  name: 'request-row',
  components: {
    UiSelect,
    UiModal,
  },
  props: {
    request: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalVisible: false,
      requestCategories,
      selectedStatus: this.request.state,
      requestStatuses: [
        { label: 'В обработке', value: 'В обработке' },
        { label: 'Обработана', value: 'Обработана' },
      ],
      reduceFields: {
        user: (user) => ({
          label: 'Пользователь',
          value: `${user.email} (ID: ${user.id})`,
        }),
        id: (id) => ({ label: `Номер заявки`, value: id }),
        customerCategory: (category) => ({
          label: 'Категория пользователя',
          value: {
            individual: 'Физическое лицо',
            entrepreneur: 'Индивидуальный предприниматель',
            legalEntity: 'Юридическое лицо',
          }[category],
        }),
        additionalFiles: (files) => ({
          label: 'Дополнительные файлы',
          value: files,
          type: 'files',
        }),
      },
    };
  },
  computed: {
    currentCattegories() {
      return this.requestCategories[this.request.customerCategory];
    },
    fieldLabels() {
      const fieldLabels = {};
      this.currentCattegories.forEach((c) => {
        c.groups.forEach((g) => {
          g.fields.forEach((f) => {
            fieldLabels[f.name] = f.label;
          });
        });
      });
      return fieldLabels;
    },
    outputFields() {
      const fields = [];
      Object.keys(this.request).forEach((key) => {
        const value = this.request[key];

        if (this.reduceFields[key]) {
          fields.push({
            key,
            ...this.reduceFields[key](this.request[key]),
          });
          return;
        }

        const label = this.fieldLabels[key];
        if (label) {
          fields.push({ key, label, value });
        }
      });
      return fields;
    },
    requestSummary() {
      return {
        title: `Заявка №${this.request.id}`,
        description: `Имя покупателя: ${this.request.customerName}`,
      };
    },
  },
  methods: {
    async changeRequestStatus(value) {
      await requestApi.setStatus(this.request.id, value);
      this.$emit('statusUpdate');
    },
    openModal() {
      this.modalVisible = true;
    },
  },
};
</script>

<style lang="scss">
.request-row {
  &:not(:last-child) {
    border-bottom: solid 1px rgb(197, 197, 197);
  }
  &__field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(197, 197, 197);
  }
  &__label {
    font-weight: 600;
    margin-right: 40px;
    max-width: 400px;
  }
  &__header {
    padding: 20px;
    display: flex;
    cursor: pointer;
    .ui-select {
      width: 150px;
      margin-left: auto;
      font-size: 16px;
    }
  }
  &__modal {
    min-width: 700px;
  }
  p,
  h2 {
    margin: 0;
  }
}
</style>
