<template>
  <div class="ui-pagination">
    <div
      v-for="pageLabel in nearestPages"
      :key="pageLabel"
      :class="['ui-pagination__page', isPageActive(pageLabel)]"
      @click="changePage(pageLabel)"
    >
      <span>
        {{ pageLabel }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-pagination',
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    nearestPages() {
      const minPage = this.page - 2 > 0 ? this.page - 2 : 1;
      const maxPage = minPage + 4 >= this.pages ? this.pages : minPage + 4;
      return [...Array(maxPage + 1).keys()].slice(minPage);
    },
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    isPageActive(pageLabel) {
      return parseInt(this.page) === pageLabel ? 'active' : '';
    },
  },
};
</script>

<style lang="scss">
.ui-pagination {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  width: 160px;
  &__page {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32.5px;
    width: 32.5px;
    flex-shrink: 0;
    transition: color ease 0.3s;
    color: #000;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #d5615c;
      border-radius: 100%;
      opacity: 0;
      transform: scale(0);
      transform-origin: center center;
      transition: opacity ease 0.3s, transform ease 0.3s;
    }
    &.active {
      color: #fff;
      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
    span {
      position: relative;
      z-index: 4;
    }
  }
}
</style>
