import axios from 'axios';

const baseURL = '/api/v1';

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'ru',
  },
});

api.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if ([422, 401].includes(error.response.status)) {
      return Promise.resolve(error.response.data);
    }

    return Promise.reject(error);
  },
);

export { api };
