import { api } from './api';

const requestApi = {
  get: (params) => api.get('/applications', { params }),
  send: (data) =>
    api.post('/applications', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getStatus: (applicationId) => api.get(`applications/${applicationId}/state`),
  setStatus: (applicationId, state) =>
    api.post(`applications/${applicationId}/state`, {
      state,
    }),
};

export { requestApi };
