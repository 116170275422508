export const EMIT_UPDATE_HEIGHT = {
  methods: {
    async emitUpdateHeight() {
      document.domain = 'teploseti34.ru';
      await this.$nextTick();
      var event = new CustomEvent('updateHeight');
      window.parent.document.dispatchEvent(event);
    },
  },
};
