<template>
  <div :class="['provider-error', { isAlert }]">
    <div class="provider-error__input">
      <slot v-bind="{ isAlert }"></slot>
    </div>

    <transition name="fade">
      <p v-if="error" class="provider-error__error">
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'provider-error',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    isAlert() {
      return !!this.error;
    },
  },
};
</script>

<style lang="scss">
.provider-error {
  position: relative;
  width: 100%;
  &__error {
    color: #a94442;
    margin: 0;
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  & + & {
    margin-top: 20px;
  }
  &.isAlert {
    margin-bottom: 0;
  }
}
</style>
