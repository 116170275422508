import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '../views/Register.vue';
import Authorization from '../views/Authorization.vue';
import Status from '../views/Status.vue';
import RestoreRequest from '../views/password/RestoreRequest.vue';
import Change from '../views/password/Change.vue';
import Request from '../views/Request.vue';
import Admin from '../views/Admin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/',
    alias: '/authorization',
    name: 'Authorization',
    component: Authorization,
  },
  {
    path: '/password/restoreRequest',
    name: 'RestoreRequest',
    component: RestoreRequest,
  },
  {
    path: '/password/change',
    name: 'Change',
    component: Change,
  },
  {
    path: '/status',
    name: 'status',
    component: Status,
  },
  {
    path: '/request',
    name: 'request',
    component: Request,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
