<template>
  <div class="ui-success">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ui-success',
};
</script>

<style lang="scss">
.ui-success {
  background-color: rgb(103, 255, 116);
  padding: 15px 20px;
  border-radius: 4px;
  margin: 10px 0;
}
</style>
