<template>
  <div class="status">
    <a href="#" class="status__logout" @click="logout">
      Выйти из Личного кабинета
    </a>

    <h1>Просмотр статуса заявки</h1>

    <ui-form class="status__form" :errors="errors" @submit.prevent="submit">
      <provider-error
        v-for="field in fields"
        :key="field.name"
        :error="errors[field.name]"
        #default="{isAlert}"
      >
        <ui-input
          v-model="values[field.name]"
          :name="field.name"
          :type="field.type"
          :required="field.required"
          :label="field.label"
          :isAlert="isAlert"
        />
      </provider-error>

      <ui-button>
        Проверить
      </ui-button>
    </ui-form>

    <transition name="fade">
      <p v-if="result" class="status__result">"{{ result }}"</p>
    </transition>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import { requestApi } from '../api/request';
import UiForm from '../components/ui/UiForm';
import UiInput from '../components/ui/UiInput';
import UiButton from '../components/ui/UiButton';
import UiLoader from '../components/ui/UiLoader';
import ProviderError from '../components/provider/ProviderError';
import { authApi } from '../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../mixins/emitUpdateHeight.mixin';

export default {
  name: 'status',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    UiInput,
    UiForm,
    UiButton,
    UiLoader,
    ProviderError,
  },
  data() {
    return {
      valid: true,
      fields: [
        { name: 'applicationId', label: 'Номер заявки', required: true },
      ],
      values: {},
      errors: {},
      result: null,
      loading: false,
    };
  },

  mounted() {
    this.emitUpdateHeight();
  },

  methods: {
    async submit() {
      const { errors, data } = await requestApi.getStatus(
        this.values.applicationId,
      );
      if (errors) {
        this.errors = errors;
        this.result = null;
      }
      if (data && data.state) {
        this.errors = {};
        this.result = data.state;
      }
      this.emitUpdateHeight();
    },
    async logout() {
      this.loading = true;
      await authApi.logout();

      this.loading = false;

      this.$router.push('Authorization');
    },
  },
};
</script>

<style lang="scss">
.status {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__form {
    .ui-form__fields {
      display: flex;
      align-items: flex-end;
    }
    .ui-input + .ui-input {
      margin-top: 0;
      margin-left: 10px;
    }
    .ui-button {
      margin-left: 10px;
    }
  }
  .provider-error.isAlert {
    margin-bottom: 0;
  }
  &__logout {
    margin-bottom: 20px;
    margin-left: auto;
  }
}
</style>
