<template>
  <label class="ui-checkbox">
    <input
      v-if="multiple"
      v-model="localValue"
      :value="name"
      :name="name"
      type="checkbox"
    />

    <input v-else type="checkbox" :name="name" v-model="localValue" />

    <span class="ui-checkbox__label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'ui-checkbox',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, Date, Number, String, Boolean],
      default: null,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    multiple() {
      return Array.isArray(this.value);
    },
  },
};
</script>

<style lang="scss">
.ui-checkbox {
  display: block;
}
</style>
