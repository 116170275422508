<template>
  <form class="ui-form" v-on="$listeners">
    <div class="ui-form__fields">
      <slot />
    </div>

    <transition name="fade">
      <div v-if="hasErrors" class="ui-form__errors">
        <p v-for="error in outputErrors" :key="error">
          {{ error }}
        </p>
      </div>
    </transition>
  </form>
</template>

<script>
export default {
  name: 'ui-form',
  props: {
    errors: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    outputErrors() {
      if (Array.isArray(this.errors)) return this.errors;

      return Object.values(this.errors);
    },

    hasErrors() {
      return this.outputErrors.length > 0;
    },
  },
};
</script>

<style lang="scss">
.ui-form {
  &__errors {
    width: 100%;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 15px 20px;
    border-radius: 4px;
    margin-top: 20px;
    p {
      margin: 0;
    }
    p + p {
      margin-top: 10px;
    }
  }
  .ui-button {
    margin-top: 20px;
  }
  & + .description {
    margin-top: 20px;
  }
  &__fields {
    width: 100%;
  }
}
</style>
