<template>
  <div class="register">
    <h1>Регистрация</h1>

    <ui-form :errors="errors" @submit.prevent="submit">
      <provider-error
        v-for="field in fields"
        :key="field.name"
        :error="errors[field.name]"
        #default="{isAlert}"
      >
        <ui-input
          v-model="values[field.name]"
          :name="field.name"
          :required="field.required"
          :type="field.type"
          :label="field.label"
          :isAlert="isAlert"
        />
      </provider-error>

      <div class="register__captcha">
        <vue-recaptcha
          sitekey="6Leshq4bAAAAAOkV8kuimDAjXW5b8nNBqZ9QCUxS"
          @verify="verifyCaptcha"
        />
      </div>

      <ui-checkbox name="terms" v-model="terms">
        Согласен на обработку персональных данных
      </ui-checkbox>

      <provider-error :error="errors.captcha">
        <ui-button :disabled="!terms">
          Регистрация
        </ui-button>
      </provider-error>
    </ui-form>

    <ui-success v-if="success">
      Успешная регистрация. Ожидайте письмо для подтверждения регистрации.
    </ui-success>

    <div class="description">
      <router-link to="/authorization">
        Авторизироваться
      </router-link>
    </div>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import ProviderError from '../components/provider/ProviderError';
import UiForm from '../components/ui/UiForm';
import UiInput from '../components/ui/UiInput';
import UiButton from '../components/ui/UiButton';
import UiCheckbox from '../components/ui/UiCheckbox';
import UiLoader from '../components/ui/UiLoader';
import UiSuccess from '../components/ui/UiSuccess';

import { authApi } from '../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../mixins/emitUpdateHeight.mixin';

export default {
  name: 'Register',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    VueRecaptcha,
    UiInput,
    UiForm,
    UiButton,
    UiCheckbox,
    UiLoader,
    UiSuccess,
    ProviderError,
  },
  data() {
    return {
      valid: true,
      fields: [
        { name: 'email', label: 'E-Mail', required: true },
        { name: 'password', label: 'Пароль', type: 'password', required: true },
        {
          name: 'passwordConfirmation',
          label: 'Подтверждение пароля',
          type: 'password',
          required: true,
        },
      ],
      values: {},
      terms: true,
      errors: {},
      loading: false,
      success: false,
    };
  },

  mounted() {
    this.emitUpdateHeight();
  },

  methods: {
    async submit() {
      this.loading = true;

      const { errors } = await authApi.register(this.values);

      this.loading = false;

      this.emitUpdateHeight();

      if (errors) {
        this.errors = errors;
        this.success = false;
        return;
      }

      this.errors = {};
      this.values = {};
      this.success = true;
    },

    verifyCaptcha(token) {
      this.values.captcha = token;
    },
  },
};
</script>

<style lang="scss">
.register {
  width: 500px;
  margin: 0 auto;
  .ui-checkbox {
    margin: 20px 0;
  }
  &__captcha {
    margin-top: 20px;
  }
}
</style>
