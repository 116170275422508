<template>
  <div :class="['ui-input', { isAlert }]">
    <label :for="name" class="ui-input__label">
      <span v-if="required" class="ui-input__required">
        *
      </span>
      {{ label }}
    </label>

    <textarea
      v-if="isTextarea"
      v-model="localValue"
      class="ui-input__input"
      :name="name"
      :type="type"
      :required="required"
    />

    <input
      v-else
      v-model="localValue"
      class="ui-input__input"
      :name="name"
      :type="type"
      :required="required"
      :multiple="isFile"
      :accept="accept"
    />
  </div>
</template>

<script>
export default {
  name: 'ui-input',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isAlert: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    isTextarea() {
      return this.type === 'textarea';
    },

    isFile() {
      return this.type === 'file';
    },

    accept() {
      return this.isFile
        ? '.jpg, .jpeg, .png, .pdf, .doc, .docx, .zip, .rar'
        : null;
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  width: 100%;
  & + & {
    margin-top: 10px;
  }
  &__label {
    display: block;
    width: 100%;
    font-size: 14px;
    margin-bottom: 3px;
  }
  &__required {
    color: #d5615c;
  }
  &__input {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.55);
    padding: 10px;
    transition: background-color 150ms ease-in, border 150ms ease-in;
    background-color: #fff;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: #f1f1f1;
      outline: none;
    }
    &[type='date'] {
      padding: 8px;
    }
  }

  textarea {
    min-height: 60px;
    resize: vertical;
  }

  &.isAlert {
    .ui-input {
      &__input {
        border: 2px solid rgba(245, 69, 69, 0.55);
      }
    }
  }
}
</style>
