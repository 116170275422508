<template>
  <div class="admin">
    <a href="#" class="admin__logout" @click="logout">
      Выйти из Панели администратора
    </a>

    <ui-loader :loading="loading" />
    <p v-if="message" :class="b('message')">
      {{ message }}
    </p>

    <div class="admin__header">
      <h1>Заявки</h1>
      <div class="admin__config">
        <ui-checkbox
          v-for="checkbox in checkboxes"
          :key="checkbox.name"
          v-model="checkboxesChecked[checkbox.name]"
          :name="checkbox.name"
          @input="resetData"
        >
          {{ checkbox.label }}
        </ui-checkbox>
      </div>
    </div>

    <div class="admin__content">
      <request-row
        v-for="request in requests"
        :key="request.id"
        :request="request"
        @statusUpdate="resetData"
      />
    </div>

    <ui-pagination v-model="activePage" :pages="pages" @input="pageChanged" />
  </div>
</template>

<script>
import { requestApi } from '../api/request';
import UiLoader from '../components/ui/UiLoader';
import UiPagination from '../components/ui/UiPagination';
import UiCheckbox from '../components/ui/UiCheckbox';
import RequestRow from '../components/admin/requestRow';
import { authApi } from '../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../mixins/emitUpdateHeight.mixin';

export default {
  name: 'admin',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    UiLoader,
    UiPagination,
    UiCheckbox,
    RequestRow,
  },
  data() {
    return {
      activePage: 1,
      perPage: 12,
      loading: false,
      message: null,
      requests: [],
      checkboxes: [
        { label: 'В обработке', name: 'applicationsInProcess' },
        { label: 'Обработана', name: 'processedApplications' },
      ],
      checkboxesChecked: {
        applicationsInProcess: true,
        processedApplications: true,
      },
    };
  },
  async mounted() {
    try {
      this.resetData();
    } catch (e) {
      console.log(e);
    }

    const { errors } = await authApi.isAuth();
    if (errors) {
      this.$router.push('Authorization');
    }
  },
  methods: {
    async resetData() {
      const { data } = await this.fetchData();

      this.pages = data.pages;
      this.requests = data.items;

      this.emitUpdateHeight();
    },
    async fetchData() {
      const data = await requestApi.get({
        perPage: this.perPage,
        page: this.activePage,
        ...this.checkboxesChecked,
      });
      return data;
    },
    pageChanged() {
      window.scroll(0, 0);
      this.resetData();
    },
    async logout() {
      this.loading = true;
      await authApi.logout();

      this.loading = false;

      this.$router.push('Authorization');
    },
  },
};
</script>

<style lang="scss">
.admin {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__message {
    width: 100px;
    background-color: rgb(255, 158, 158);
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__config {
    display: flex;
    .ui-checkbox + .ui-checkbox {
      margin-left: 20px;
    }
  }
  .ui-pagination {
    margin: 0 auto;
  }
  &__content {
    margin-bottom: 20px;
    width: 100%;
  }
  &__logout {
    margin-bottom: 20px;
    margin-left: auto;
  }
}
</style>
