<template>
  <transition name="fade">
    <div v-if="loading" class="ui-loader">
      <div class="ui-loader__circle"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ui-loader',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ui-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  &__circle {
    width: 80px;
    height: 80px;
    &:after {
      content: ' ';
      display: block;
      width: 52px;
      height: 52px;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid #d5615c;
      border-color: #d5615c transparent #d5615c transparent;
      animation: lds-dual-ring 0.9s linear infinite;
    }
  }
}
</style>
