import { api } from './api';

const authApi = {
  login: (params) => api.post('/auth/login', params),
  register: (params) => api.post('/auth/registration', params),
  restoreRequest: (params) => api.post('/auth/reset-password', params),
  changePassword: (token, params) =>
    api.post(`/auth/reset-password/${token}/`, params),
  isAuth: () => api.get(`/auth/is-auth`),
  logout: () => api.post(`/auth/logout`),
};

export { authApi };
