<template>
  <div class="request">
    <a href="#" class="request__logout" @click="logout">
      Выйти из Личного кабинета
    </a>

    <h1>Заявка на подключение к системе теплоснабжения</h1>

    <div class="request__header">
      <ui-select v-model="selectedCategory" :options="userCategories" />

      <router-link to="/status">
        Проверить статус заявки
      </router-link>
    </div>

    <transition name="fade" mode="out-in">
      <ui-form
        ref="form"
        v-if="selectedCategory"
        :key="selectedCategory"
        class="request__form"
        :errors="errors"
        @submit.prevent="submit"
      >
        <div
          v-for="category in currentCattegories"
          :key="category.name"
          class="request__category"
        >
          <h2 v-if="category.title">{{ category.title }}</h2>
          <p v-if="category.subtitle">{{ category.subtitle }}</p>

          <div class="request__groups">
            <div
              v-for="group in category.groups"
              :key="group.title"
              :class="['request__group', group.size]"
            >
              <h3>{{ group.title }}</h3>
              <div class="request__inputs">
                <provider-error
                  v-for="field in group.fields"
                  :key="field.name"
                  :class="['request__input', field.size]"
                  :error="errors[field.name]"
                  #default="{isAlert}"
                >
                  <ui-input
                    v-model="values[field.name]"
                    :name="field.name"
                    :type="field.type"
                    :required="field.required"
                    :label="field.label"
                    :isAlert="isAlert"
                  />

                  <p v-if="field.description" v-html="field.description"></p>
                </provider-error>
              </div>
            </div>
          </div>
        </div>

        <ui-success v-if="successStatusId">
          Успешно! Вашей заявке присвоен номер "{{ successStatusId }}"
        </ui-success>

        <ui-button>
          Отправить
        </ui-button>
      </ui-form>
    </transition>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import { requestApi } from '../api/request';
import { requestCategories } from '../consts/request';
import ProviderError from '../components/provider/ProviderError';
import UiForm from '../components/ui/UiForm';
import UiInput from '../components/ui/UiInput';
import UiButton from '../components/ui/UiButton';
import UiSelect from '../components/ui/UiSelect';
import UiLoader from '../components/ui/UiLoader';
import UiSuccess from '../components/ui/UiSuccess';
import { authApi } from '../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../mixins/emitUpdateHeight.mixin';

export default {
  name: 'request',

  mixins: [EMIT_UPDATE_HEIGHT],

  components: {
    ProviderError,
    UiInput,
    UiForm,
    UiButton,
    UiSelect,
    UiLoader,
    UiSuccess,
  },

  data() {
    return {
      requestCategories,
      selectedCategory: null,
      userCategories: [
        { label: 'Категория потребителя', value: null },
        { label: 'Физическое лицо', value: 'individual' },
        { label: 'Индивидуальный предприниматель', value: 'entrepreneur' },
        { label: 'Юридическое лицо', value: 'legalEntity' },
      ],
      values: {},
      errors: {},
      loading: false,
      successStatusId: null,
    };
  },

  computed: {
    currentCattegories() {
      return this.requestCategories[this.selectedCategory];
    },
  },

  watch: {
    selectedCategory() {
      this.emitUpdateHeight();
    },
  },

  async mounted() {
    const { errors } = await authApi.isAuth();
    this.emitUpdateHeight();
    if (errors) {
      this.$router.push('Authorization');
    }
  },

  methods: {
    async submit() {
      this.loading = true;

      const model = new FormData(this.$refs.form.$el);
      model.append('customerCategory', this.selectedCategory);

      const { errors, data } = await requestApi.send(model);

      this.loading = false;

      if (errors) {
        this.errors = this.getErrorsWithLabels(errors);
        return;
      }
      if (data.id) {
        this.errors = false;
        this.successStatusId = data.id;
        this.values = {};
      }
      this.emitUpdateHeight();
    },
    getErrorsWithLabels(errors) {
      const allFields = {};
      this.currentCattegories.forEach((c) => {
        c.groups.forEach((g) => {
          g.fields.forEach((f) => {
            allFields[f.name] = f.label;
          });
        });
      });
      return Object.entries(errors).reduce((total, [key, value]) => {
        const fieldLabel = allFields[key] ? `${allFields[key]}: ` : '';
        total[key] = `${fieldLabel}${value}`;
        return total;
      }, {});
    },
    async logout() {
      this.loading = true;
      await authApi.logout();

      this.loading = false;

      this.$router.push('Authorization');
    },
  },
};
</script>

<style lang="scss">
.request {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .ui-select {
    margin-bottom: 20px;
    width: 300px;
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  &__category {
    width: 100%;
  }
  &__groups {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    &.half {
      width: calc(50% - 5px);
      & + & {
        margin-left: 5px;
      }
      @media screen and (max-width: 450px) {
        width: 100%;
        margin-left: 0 !important;
      }
    }
    &.third {
      width: calc(100% / 3 - 15px);
      & + & {
        margin-left: 5px;
      }
      @media screen and (max-width: 450px) {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }
  &__inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
  }
  &__input {
    width: 100%;
    &.half {
      width: calc(50% - 5px);
      & + & {
        margin-left: 7.5px;
        & + & {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 640px) {
        width: 100%;
        margin-left: 0 !important;
      }
    }
    &.third {
      width: calc((100% / 3) - 5px);
      & + & {
        margin-left: 7.5px;
        & + & {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 640px) {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }

  .ui-input + .ui-input {
    margin-top: 0;
  }

  .ui-input {
    margin-bottom: 10px;
  }

  .ui-select {
    margin-right: 30px;
  }

  .provider-error__error {
    top: calc(100% - 10px);
  }

  &__logout {
    margin-bottom: 20px;
    margin-left: auto;
  }
}
</style>
