const general = [
  {
    name: 'heat',
    title: 'Расчетные расходы тепловой энергии по объекту, Гкал/час',
    groups: [
      {
        title: 'Максимальные часовые:',
        size: 'half',
        fields: [
          { name: 'energyMaxAll', type: 'text', label: 'Общая' },
          { name: 'energyMaxHeating', type: 'text', label: 'Отопление' },
          {
            name: 'energyMaxVentilation',
            type: 'text',
            label: 'Вентиляция',
          },
          {
            name: 'energyMaxHotWaterSupply',
            type: 'text',
            label: 'Горячее водоснабжение',
          },
        ],
      },
      {
        title: 'Минимальные часовые:',
        size: 'half',
        fields: [
          { name: 'energyMinAll', type: 'text', label: 'Общая' },
          { name: 'energyMinHeating', type: 'text', label: 'Отопление' },
          {
            name: 'energyMinVentilation',
            type: 'text',
            label: 'Вентиляция',
          },
          {
            name: 'energyMinHotWaterSupply',
            type: 'text',
            label: 'Горячее водоснабжение',
          },
        ],
      },
    ],
  },
  {
    name: 'consumption',
    title: 'Расчетные расходы теплоносителей, т/час',
    groups: [
      {
        title: 'Максимальные часовые:',
        size: 'half',
        fields: [
          { name: 'heatCarrierMaxAll', type: 'text', label: 'Общая' },
          {
            name: 'heatCarrierMaxHeating',
            type: 'text',
            label: 'Отопление',
          },
          {
            name: 'heatCarrierMaxVentilation',
            type: 'text',
            label: 'Вентиляция',
          },
          {
            name: 'heatCarrierMaxHotWaterSupply',
            type: 'text',
            label: 'Горячее водоснабжение',
          },
        ],
      },
      {
        title: 'Минимальные часовые:',
        size: 'half',
        fields: [
          { name: 'heatCarrierMinAll', type: 'text', label: 'Общая' },
          {
            name: 'heatCarrierMinHeating',
            type: 'text',
            label: 'Отопление',
          },
          {
            name: 'heatCarrierMinVentilation',
            type: 'text',
            label: 'Вентиляция',
          },
          {
            name: 'heatCarrierMinHotWaterSupply',
            type: 'text',
            label: 'Горячее водоснабжение',
          },
        ],
      },
    ],
  },
  {
    name: 'type',
    title: 'Вид и параметры теплоносителей',
    subtitle: 'Давление (*необязательно для заполнения):',
    groups: [
      {
        title: '- в отопительный период:',
        size: 'half',
        fields: [
          {
            name: 'barHeatingPeriodTo',
            type: 'text',
            label: 'в подающем трубопроводе',
          },
          {
            name: 'barHeatingPeriodTake',
            type: 'text',
            label: 'в обратном трубопроводе',
          },
          {
            name: 'barHeatingPeriodStatic',
            type: 'text',
            label: 'в статическом состоянии',
          },
        ],
      },
      {
        title: '- в межотопительный период:',
        size: 'half',
        fields: [
          {
            name: 'barInterHeatingPeriodTo',
            type: 'text',
            label: 'в подающем трубопроводе',
          },
          {
            name: 'barInterHeatingPeriodTake',
            type: 'text',
            label: 'в обратном трубопроводе',
          },
        ],
      },
    ],
  },
  {
    name: 'temperature',
    title: 'Температура (*необязательно для заполнения):',
    groups: [
      {
        title: '',
        fields: [
          { name: 'temperature', type: 'text', label: '°C', size: 'half' },
          {
            name: 'heatConsumptionModes',
            type: 'textarea',
            label: 'Режимы теплопотребления для подключаемого объекта',
          },
          {
            name: 'accountingSiteLocation',
            type: 'textarea',
            label:
              'Расположение узла учета тепловой энергии и теплоносителей и контроля их качества',
          },
          {
            name: 'reliabilityRequirements',
            type: 'textarea',
            label:
              'Требования к надёжности теплоснабжения подключаемого объекта',
          },
          {
            name: 'allowableBreaks',
            type: 'textarea',
            label:
              'Допустимые перерывы в подаче теплоносителей по продолжительности, периодам года',
          },
          {
            name: 'usingOwnSources',
            type: 'textarea',
            label:
              'Наличие и возможность использования собственных источников тепловой энергии с указанием их мощностей и режимов работы',
          },
          {
            name: 'legalGrounds',
            type: 'textarea',
            label:
              'Правовые основания пользования заявителем подключаемым объектом и земельным участком, на котором планируется создание подключаемого объекта',
          },
        ],
      },
    ],
  },
  {
    name: 'technical',
    title: 'Данные технических условий, если они выдавались ранее',
    groups: [
      {
        title: '',
        fields: [
          {
            name: 'technicalDataNumber',
            type: 'text',
            label: 'номер',
            size: 'third',
          },
          {
            name: 'technicalDataDate',
            type: 'date',
            label: 'дата выдачи',
            size: 'third',
          },
        ],
      },
    ],
  },
  {
    name: 'dates',
    title: 'Планируемые сроки ввода в эксплуатацию подключаемого объекта',
    groups: [
      {
        title: '',
        fields: [
          {
            name: 'commissioningDate',
            type: 'date',
            label: 'выберите дату',
            size: 'third',
          },
          {
            name: 'borderInformation',
            type: 'textarea',
            label:
              'Информация о границах земельного участка, на котором планируется осуществить строительство (реконструкцию, модернизацию) подключаемого объекта',
          },
          {
            name: 'typeInformation',
            type: 'textarea',
            label:
              'Информация о виде разрешенного использования земельного участка',
          },
          {
            name: 'paramsInformation',
            type: 'textarea',
            label:
              'Информация о предельных параметрах разрешенного строительства (реконструкции, модернизации) подключаемого объекта',
          },
          {
            name: 'additionalFiles[]',
            type: 'file',
            label:
              'Прикрепить файлы (доступные форматы: jpg, jpeg, png, pdf, doc, docx, zip, rar)',
          },
          {
            name: 'additionalFileDescription',
            type: 'textarea',
            label: 'Описание прикрепляемых файлов',
            description: `Перечень прикладываемых документов:
            <br /> 1. Копии правоустанавливающих документов на подключаемый объект или земельный участок, при регистрации прав на объект в ЕГРН – соответствующая выписка из реестра.
            <br /> 2. Ситуационный план расположения подключаемого объекта с привязкой к территории населенного пункта или элементам территориального деления в схеме теплоснабжения.
            <br /> 3. Топографическая карта земельного участка в масштабе 1:500 (для квартальной застройки 1:2000) с указанием всех наземных и подземных коммуникаций и сооружений (не прилагать в случае если заявитель – физ.лицо, осуществляющее создание (реконструкцию) индивидуального жилищного строительства.
            <br /> 4. Документы, подтверждающие полномочия лица, действующего от имени заявителя (по доверенности, представительство).
            <br /> 5. Копии учредительных документов – для юр.лиц.`,
          },
        ],
      },
    ],
  },
];

const requestCategories = {
  individual: [
    {
      name: 'name',
      title: '',
      groups: [
        {
          title: '',
          fields: [
            {
              name: 'customerName',
              label: 'ФИО',
              required: true,
            },
          ],
        },
        {
          title: 'Реквизиты потребителя',
          fields: [
            {
              name: 'pasportSeries',
              label:
                'Серия паспорта или иного документа, удостоверяющего личность',
              size: 'third',
            },
            {
              name: 'pasportNumber',
              label:
                'Номер паспорта или иного документа, удостоверяющего личность',
              size: 'third',
            },
            {
              name: 'pasportDate',
              label:
                'Дата выдачи паспорта или иного документа, удостоверяющего личность',
              type: 'date',
              size: 'third',
            },
            {
              name: 'registrationAddress',
              label: 'Адрес регистрации',
            },
            {
              name: 'mailingAddress',
              label: 'Почтовый адрес заявителя',
              type: 'textarea',
            },
            {
              name: 'customerEmail',
              type: 'email',
              label: 'Адрес электронной почты потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'customerPhone',
              type: 'tel',
              label: 'Номер телефона потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'typeObject',
              type: 'text',
              label: 'Тип объекта подключения',
              size: 'third',
            },
            {
              name: 'objectAddress',
              type: 'textarea',
              label: 'Адрес подключаемого объекта',
            },
          ],
        },
      ],
    },
    ...general,
  ],
  entrepreneur: [
    {
      name: 'name',
      title: '',
      groups: [
        {
          title: '',
          fields: [
            {
              name: 'customerName',
              label: 'ФИО предпринимателя',
              required: true,
            },
          ],
        },
        {
          title: 'Данные индивидуального предпринимателя',
          fields: [
            {
              name: 'inn',
              label: 'ИНН',
              size: 'half',
            },
            {
              name: 'innDate',
              label:
                'Номер и дата внесения в ЕГРИП записи о государственной регистрации',
              type: 'date',
              size: 'half',
            },
            {
              name: 'mailingAddress',
              label: 'Почтовый адрес заявителя',
              type: 'textarea',
            },
            {
              name: 'customerEmail',
              type: 'email',
              label: 'Адрес электронной почты потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'customerPhone',
              type: 'tel',
              label: 'Номер телефона потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'typeObject',
              type: 'text',
              label: 'Тип объекта подключения',
              size: 'third',
            },
            {
              name: 'objectAddress',
              type: 'textarea',
              label: 'Адрес подключаемого объекта',
            },
          ],
        },
      ],
    },
    ...general,
  ],
  legalEntity: [
    {
      name: 'name',
      title: '',
      groups: [
        {
          title: '',
          fields: [
            {
              name: 'customerName',
              label: 'Полное название организации в соответствии с Уставом',
              required: true,
            },
          ],
        },
        {
          title: 'Данные юридического лица',
          fields: [
            {
              name: 'inn',
              label: 'ИНН',
              size: 'half',
            },
            {
              name: 'innDate',
              label:
                'Номер и дата внесения в ЕГРЮЛ записи о государственной регистрации юр. лица',
              type: 'date',
              size: 'half',
            },
            {
              name: 'mailingAddress',
              label: 'Почтовый адрес заявителя',
              type: 'textarea',
            },
            {
              name: 'customerEmail',
              type: 'email',
              label: 'Адрес электронной почты потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'customerPhone',
              type: 'tel',
              label: 'Номер телефона потребителя',
              size: 'third',
              required: true,
            },
            {
              name: 'typeObject',
              type: 'text',
              label: 'Тип объекта подключения',
              size: 'third',
            },
            {
              name: 'objectAddress',
              type: 'textarea',
              label: 'Адрес подключаемого объекта',
            },
          ],
        },
      ],
    },
    ...general,
  ],
};

export { requestCategories };
