<template>
  <div class="restore-request">
    <h1>Выслать контрольную строку</h1>

    <p>
      Если вы забыли пароль, введите логин или E-Mail. Контрольная строка для
      смены пароля, а также ваши регистрационные данные, будут высланы вам по
      E-Mail
    </p>

    <ui-form :errors="errors" @submit.prevent="submit">
      <provider-error
        v-for="field in fields"
        :key="field.name"
        :error="errors[field.name]"
        #default="{isAlert}"
      >
        <ui-input
          v-model="values[field.name]"
          :name="field.name"
          :required="field.required"
          :label="field.label"
          :isAlert="isAlert"
        />
      </provider-error>

      <ui-button>
        Выслать
      </ui-button>
    </ui-form>

    <ui-success v-if="success">
      Ссылка для восстановления пароля успешно отправлена на Вашу почту.
    </ui-success>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import ProviderError from '../../components/provider/ProviderError';
import UiForm from '../../components/ui/UiForm';
import UiInput from '../../components/ui/UiInput';
import UiButton from '../../components/ui/UiButton';
import UiLoader from '../../components/ui/UiLoader';
import UiSuccess from '../../components/ui/UiSuccess';

import { authApi } from '../../api/auth';
import { EMIT_UPDATE_HEIGHT } from '../../mixins/emitUpdateHeight.mixin';

export default {
  name: 'password-restore-request',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    UiInput,
    UiForm,
    UiButton,
    UiLoader,
    ProviderError,
    UiSuccess,
  },
  data() {
    return {
      fields: [{ name: 'email', label: 'E-mail', required: true }],
      values: {},
      errors: {},
      loading: false,
      success: false,
    };
  },

  mounted() {
    this.emitUpdateHeight();
  },

  methods: {
    async submit() {
      this.loading = true;

      const { errors } = await authApi.restoreRequest(this.values);

      this.loading = false;

      if (errors) {
        this.errors = errors;
        this.success = false;
        this.emitUpdateHeight();
        return;
      }

      this.errors = {};
      this.values = {};
      this.success = true;
    },
  },
};
</script>

<style lang="scss">
.restore-request {
  width: 500px;
  margin: 0 auto;
}
</style>
