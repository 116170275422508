<template>
  <div v-show="loaded" class="authorization">
    <h1>Авторизация</h1>

    <ui-form :errors="errors" @submit.prevent="submit">
      <provider-error
        v-for="field in fields"
        :key="field.name"
        :error="errors[field.name]"
        #default="{isAlert}"
      >
        <ui-input
          v-model="values[field.name]"
          :name="field.name"
          :required="field.required"
          :label="field.label"
          :type="field.type"
          :isAlert="isAlert"
        />
      </provider-error>

      <ui-checkbox name="terms" v-model="values.isRemember">
        Запомнить меня на этом компьютере
      </ui-checkbox>

      <ui-button>
        Войти
      </ui-button>
    </ui-form>

    <div class="description">
      <router-link to="/password/restoreRequest">
        Забыли свой пароль?
      </router-link>
      <p>
        Если вы впервые на сайте, заполните, пожалуйста, регистрационную форму.
        <router-link to="/register">
          Зарегистрироваться
        </router-link>
      </p>
    </div>

    <ui-loader :loading="loading" />
  </div>
</template>

<script>
import ProviderError from '../components/provider/ProviderError';
import UiForm from '../components/ui/UiForm';
import UiInput from '../components/ui/UiInput';
import UiButton from '../components/ui/UiButton';
import UiCheckbox from '../components/ui/UiCheckbox';
import UiLoader from '../components/ui/UiLoader';

import { authApi } from '../api/auth';

import { EMIT_UPDATE_HEIGHT } from '../mixins/emitUpdateHeight.mixin';

export default {
  name: 'authorization',
  mixins: [EMIT_UPDATE_HEIGHT],
  components: {
    ProviderError,
    UiInput,
    UiForm,
    UiButton,
    UiCheckbox,
    UiLoader,
  },
  data() {
    return {
      fields: [
        { name: 'email', label: 'Email', required: true },
        { name: 'password', type: 'password', label: 'Пароль', required: true },
      ],
      values: {
        isRemember: true,
      },
      errors: {},
      loading: false,
      loaded: false,
    };
  },
  async mounted() {
    const { errors, data } = await authApi.isAuth();
    if (data && data.user?.isAdmin) {
      this.$router.push('Admin');
      return;
    }

    if (!errors) {
      this.$router.push('Request');
      return;
    }
    this.loaded = true;

    this.emitUpdateHeight();
  },
  methods: {
    async submit() {
      this.loading = true;

      const { errors, message, data } = await authApi.login(this.values);

      this.loading = false;

      if (errors || message) {
        this.success = false;
        this.errors = errors || [message];
        return;
      }

      if (data && !data.user?.isVerified) {
        this.errors = { register: 'Вы не подтвердили свою почту' };
        return;
      }

      this.errors = {};

      if (data && data.user?.isAdmin) {
        this.$router.push('Admin');
        return;
      }

      this.$router.push('Request');
    },
  },
};
</script>

<style lang="scss">
.authorization {
  width: 500px;
  margin: 0 auto;
  position: relative;
  .ui-checkbox {
    margin: 20px 0;
  }
}
</style>
