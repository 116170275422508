<template>
  <button class="ui-button" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ui-button',
  props: {
    type: {
      type: String,
      default: 'submit',
    },
  },
};
</script>

<style lang="scss">
.ui-button {
  color: #fff;
  margin-right: 4px;
  font-size: 14px;
  line-height: 1;
  padding: 12px 20px;
  font-weight: 700;
  border-radius: 0;
  text-shadow: none;
  text-transform: uppercase;
  transition: background-color 150ms ease-in;
  box-shadow: none;
  display: inline-block;
  background-color: #d5615c;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #e76863;
    border: none;
    outline: none;
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
</style>
