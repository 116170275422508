<template>
  <transition name="fade">
    <div v-if="visible" class="modal" @click="closeClick">
      <div ref="modal-block" class="modal__block">
        <slot @close="close" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ui-modal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          document.body.style.overflow = 'hidden';
          return;
        }
        document.body.style.overflow = 'auto';
      },
      immediate: true,
    },
  },
  methods: {
    closeClick(e) {
      if (
        this.$refs['modal-block'] !== e.target &&
        !this.$refs['modal-block'].contains(e.target)
      ) {
        this.close();
      }
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden auto;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 100;
  &__block {
    background-color: #fff;
    padding: 20px 10px;
  }
}
</style>
